<div class="isolation-auto">
    <div class="p-2" style="border: 0px solid red; font-weight: bold;">
        <div class="header px-2">
            <h1>Documento emitido por Clínica Alemana.</h1>
        </div>
    </div>
    <div class="px-3 py-0">
        <div class="" style="font-weight: bold; font-size: 20px; color: #01a49a">
            Detalle Documento
        </div>
        <hr>
        <div class="d-flex justify-content-start w-100 containerTitulos" style="padding: 9px 0;">
            <div class="d-flex flex-row contentTitle">
                <div class="titulo">
                    Identificador:
                </div>
                <div>
                    {{ documento.Data.uuid }}
                </div>
            </div>
            <div class="d-flex flex-row contentTitle">
                <div class="titulo">
                    Fecha Emisión:
                </div>
                <div>
                    {{documento.Data.fechaEmision}}
                </div>
            </div>
            <div class="d-flex flex-row contentTitle">
                <div class="titulo">
                    Tipo de Documento:
                </div>
                <div>
                    {{documento.Data.tipoDocumento}}
                </div>
            </div>
        </div>
        <div *ngIf="(tipoVisorDatos === 'JSON')" class="ngx-json-viewer" style="padding: 0 !important">
            <ngx-json-viewer [json]="objDocu" class="prueba2" [expandable]="false" [expanded]="true"></ngx-json-viewer>
        </div>
        <div *ngIf="(tipoVisorDatos === 'HTML')" style="margin-top:20px">
            <div [innerHTML]="metadataHtml"></div>
        </div>
        <div *ngIf="(tipoVisorDatos === 'MD')" style="margin-top:20px" >
            <markdown  [data]="metadataMD"></markdown>
            <!-- <div [innerHTML]="metadataMD  "></div> -->
        </div>

        <div class="row d-flex px-3 py-3" style="border: 0px solid black;">
            <div class="col-10" style="border: 0px solid black"></div>
            <div class="col-1 align-self-end" style="border: 0px solid black">
                <button class="btn btn-primary" (click)="onVolver()">
                    Volver
                </button>
            </div>
        </div>
    </div>
</div>