import { CUSTOM_ELEMENTS_SCHEMA, Injector, LOCALE_ID, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { createCustomElement } from '@angular/elements';
import { FormBuilder, FormsModule } from '@angular/forms';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { environment } from 'src/environments/environment';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing.module';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms'
import { TraductorService } from '@cas/cas-lib-mf-core';
import { LayoutComponent } from './components/layout/layout.component';
import localeEsCl from '@angular/common/locales/es-CL';
// Sistema de diseño nuevo.
//
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { InicioComponent } from './components/inicio/inicio.component';
import { InfoComponent } from './components/info/info.component';
import { NotFoundComponent } from './components/not-found/not-foud.component';

// const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
import { RecaptchaModule } from "ng-recaptcha";
import { DatePipe, registerLocaleData } from '@angular/common';
import { CasDsUikitModule } from '@cas-ds/uikit';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { MarkdownModule } from 'ngx-markdown';

import 'prismjs';
import 'prismjs/components/prism-typescript.min.js';
import 'prismjs/plugins/line-numbers/prism-line-numbers.js';
import 'prismjs/plugins/line-highlight/prism-line-highlight.js';

registerLocaleData(localeEsCl, 'es-CL');

@NgModule({
    declarations: [
        AppComponent,
        LayoutComponent,
        HeaderComponent,
        FooterComponent,
        InicioComponent,
        InfoComponent,
        NotFoundComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        RecaptchaModule,
        CasDsUikitModule,
        RouterModule,
        GoogleTagManagerModule.forRoot({
            id: environment.googleTagManagerId,
        }),
        RouterModule.forRoot([]),
        AppRoutingModule,
        HttpClientModule,
        ReactiveFormsModule,
        NgxJsonViewerModule,       
        MarkdownModule.forRoot()
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA],
    providers: [
        { provide: LOCALE_ID, useValue: 'es-CL' },
        FormBuilder,
        TraductorService,
        CasDsUikitModule,
        DatePipe,
        BsLocaleService
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
    constructor(private injector: Injector) { }

    ngDoBootstrap() {
        if ((window.customElements.get('cas-mf-validador-documentos') === undefined)) {
            const customElement = createCustomElement(AppComponent, { injector: this.injector });
            window.customElements.define('cas-mf-validador-documentos', customElement);
        }
    }
}