<header>
    <a routerLink="/" title="Volver a inicio"><img src="../../../assets/images/Firma-dispositivo/cas-logo.png"
            alt="Logo clínica alemana"></a>
    <div class="contacts">
        <div class="help-desk">
            <p>Mesa Central</p>
            <p>+56 2 2210 1111</p>
        </div>
        <div class="divider"></div>
        <div class="rescue-alemana">
            <p>Rescate Alemana</p>
            <p>+56 2 2910 9911</p>
        </div>
    </div>
</header>