import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MarkdownComponent } from 'ngx-markdown';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
})
export class InfoComponent implements OnInit, AfterViewInit {

  //VARIABLES
  @Input () documento: any = {};
  @Output () emitExit: EventEmitter<Array<any>> = new EventEmitter();
  spinner: boolean = false;
  hashDocumento: string = '';
  objDocu: any = {};
  respuestaServicio: any = {};
  tipoVisorDatos:string='JSON';
  metadataHtml: SafeHtml='';
  metadataMD: string='';
  
  constructor(private sanitizer: DomSanitizer) { 
  }

  ngAfterViewInit(): void {
   // this.objDocu = JSON.parse(this.documento.Data.metadata)
  }

  async ngOnInit(): Promise<void> {
    this.tipoVisorDatos = (this.documento.Data.tipoMetadata);

   //  var mdown:string =`<!DOCTYPE html>
   //  <html lang="es">
   //  <head>
   //      <meta charset="UTF-8">
   //      <meta name="viewport" content="width=device-width, initial-scale=1.0">
   //      <style>
   //          body {
   //              font-family: Arial, sans-serif;
   //              background-color: #f0f0f0;
   //              margin: 0;
   //              padding: 0;
   //          }
    
   //          .container {
   //              max-width: 800px;
   //              margin: 20px auto;
   //              text-align: center;
   //          }
    
   //          h1 {
   //              color: red;
   //          }
    
   //          img {
   //              max-width: 100%;
   //              height: auto;
   //              margin-top: 20px;
   //          }
   //      </style>
   //      <title>Ejemplo HTML con CSS e Imagen</title>
   //  </head>
   //  <body>
    
   //      <div class="container">
   //          <h1>Bienvenido a mi página</h1>
   //          <svg
   //          xmlns:dc="http://purl.org/dc/elements/1.1/"
   //          xmlns:cc="http://creativecommons.org/ns#"
   //          xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
   //          xmlns:svg="http://www.w3.org/2000/svg"
   //          xmlns="http://www.w3.org/2000/svg"
   //          xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   //          xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   //          width="100.276pt"
   //          height="150.89pt"
   //          viewBox="0 0 595.276 841.89"
   //          fill-rule="evenodd"
   //          id="svg3021"
   //          version="1.1"
   //          inkscape:version="0.48.4 r9939"
   //          sodipodi:docname="PawPrint4.ai">
   //         <metadata
   //            id="metadata3039">
   //           <rdf:RDF>
   //             <cc:Work
   //                rdf:about="">
   //               <dc:format>image/svg+xml</dc:format>
   //               <dc:type
   //                  rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
   //               <dc:title></dc:title>
   //             </cc:Work>
   //           </rdf:RDF>
   //         </metadata>
   //         <defs
   //            id="defs3037" />
   //         <sodipodi:namedview
   //            pagecolor="#ffffff"
   //            bordercolor="#666666"
   //            borderopacity="1"
   //            objecttolerance="10"
   //            gridtolerance="10"
   //            guidetolerance="10"
   //            inkscape:pageopacity="0"
   //            inkscape:pageshadow="2"
   //            inkscape:window-width="1024"
   //            inkscape:window-height="708"
   //            id="namedview3035"
   //            showgrid="false"
   //            inkscape:zoom="0.46846973"
   //            inkscape:cx="372.04749"
   //            inkscape:cy="433.62355"
   //            inkscape:window-x="-4"
   //            inkscape:window-y="-4"
   //            inkscape:window-maximized="1"
   //            inkscape:current-layer="svg3021" />
   //         <g
   //            id="g3023"
   //            transform="translate(269.81467,-650.62904)">
   //           <path
   //              style="fill:#2c2c2b;stroke:none"
   //              d="m -126.267,1038.85 c 22.737,50.44 15.792,102.75 -15.51,116.87 -31.303,14.12 -75.11,-15.31 -97.845,-65.74 -22.737,-50.43 -15.793,-102.745 15.51,-116.863 31.303,-14.114 75.108,15.317 97.845,65.733 z"
   //              id="path3025"
   //              inkscape:connector-curvature="0" />
   //           <path
   //              style="fill:#2c2c2b;stroke:none"
   //              d="m 183.155,1038.85 c -22.738,50.44 -15.793,102.75 15.512,116.87 31.303,14.12 75.106,-15.31 97.846,-65.74 22.734,-50.43 15.789,-102.745 -15.513,-116.863 -31.301,-14.114 -75.108,15.317 -97.845,65.733 z"
   //              id="path3027"
   //              inkscape:connector-curvature="0" />
   //           <path
   //              style="fill:#2c2c2b;stroke:none"
   //              d="m 6.7856,937.757 c 11.6548,54.069 -6.1108,103.763 -39.6787,111.003 -33.5654,7.23 -70.2249,-30.74 -81.8779,-84.804 -11.653,-54.068 6.112,-103.764 39.6792,-110.997 33.5669,-7.236 70.2246,30.729 81.8774,84.798 z"
   //              id="path3029"
   //              inkscape:connector-curvature="0" />
   //           <path
   //              style="fill:#2c2c2b;stroke:none"
   //              d="m 49.2676,937.803 c -11.6446,54.068 6.1084,103.767 39.6738,110.997 33.5676,7.24 70.2256,-30.73 81.8776,-84.797 11.654,-54.069 -6.109,-103.765 -39.678,-110.998 -33.5678,-7.234 -70.225,30.729 -81.8734,84.798 z"
   //              id="path3031"
   //              inkscape:connector-curvature="0" />
   //           <path
   //              style="fill:#2c2c2b;stroke:none"
   //              d="m -35.2275,1118.5 c -8.1924,14.15 -46.1563,60.99 -72.4145,76.97 -26.256,15.98 -58.792,39.38 -53.332,93.11 5.457,53.74 60.575,76.74 96.8597,74.7 36.2867,-2.03 104.6993,-8.71 153.543,-1.94 48.8413,6.77 110.4863,1.64 124.9223,-49.81 14.436,-51.45 -17.85,-84.23 -43.044,-102.83 -25.193,-18.59 -67.265,-74.2 -80.2269,-99.73 -12.96,-25.52 -78.9268,-72.26 -126.3076,9.53 z"
   //              id="path3033"
   //              inkscape:connector-curvature="0" />
   //         </g>
   //       </svg>
         
   //          <p>Este es un ejemplo de página HTML con CSS e imagen.</p>
   //      </div>
    
   //  </body>
   //  </html>`;
// var mdown:string =`# Ejemplo de archivo markdown ⚙️

// Este es un ejemplo de un archivo markdown. Se puede utilizar para crear documentos de texto, notas, artículos, etc.

// ## Encabezados

// Los encabezados se utilizan para organizar el texto y crear una jerarquía. Se pueden crear encabezados de hasta seis niveles utilizando almohadillas.

// ### Encabezado de nivel 2

// #### Encabezado de nivel 3

// ##### Encabezado de nivel 4

// ###### Encabezado de nivel 5

// ####### Encabezado de nivel 6

// ## Texto

// El texto se puede escribir de forma normal. Se pueden utilizar espacios en blanco para separar las palabras y las frases.

// **Negrita**

// * Cursiva

// **Tachado**

// ## Listas

// Se pueden utilizar listas para enumerar elementos. Las listas pueden ser numeradas o con viñetas.

// **Lista numerada**

// 1. Elemento 1
// 2. Elemento 2
// 3. Elemento 3

// **Lista con viñetas**

// * Elemento 1
// * Elemento 2
// * Elemento 3

// ## Enlaces

// Se pueden utilizar enlaces para crear hipervínculos. Los enlaces se crean utilizando corchetes y el texto del enlace.

// [Este es un enlace](https://www.google.com)

// ## Imágenes

// Se pueden utilizar imágenes para agregar contenido visual a un documento. Las imágenes se insertan utilizando el símbolo de almohadilla doble seguido de la URL de la imagen.

// [![Perrito](https://media.istockphoto.com/id/187451538/es/foto/beagle-cachorro-howling-mirando-hacia-arriba-aislado-en-blanco.jpg?s=612x612&w=0&k=20&c=Mqr-2N4U6YSzKya2XSaLKX2h8A28vBoA3PXX-YrIlDI=)]

// ## Código

// Se puede utilizar código para mostrar código fuente. El código se inserta utilizando el símbolo de almohadilla triple seguido del código.

// \`\`\`python
// print("Hola, mundo!")
// \`\`\`

// ## Otros elementos

// Markdown también admite otros elementos, como tablas, citas, etc. Para obtener más información, consulte la [documentación de Markdown](https://daringfireball.net/projects/markdown/).


// Este archivo markdown muestra algunos de los elementos básicos de Markdown. Se puede utilizar como punto de partida para crear sus propios documentos markdown.

// Aquí hay algunos consejos para escribir markdown:

// * Use espacios en blanco para separar las palabras y las frases.
// * Use comas para separar los elementos de una lista.
// * Use corchetes para crear enlaces.
// * Use almohadilla doble para insertar imágenes.
// * Use almohadilla triple para insertar código.

// Con un poco de práctica, podrá escribir markdown de forma rápida y sencilla.
// `;

// let jsonEntrada={
//    metadata:mdown,
//    tipoMetadata:'HTML'
// }
// this.tipoVisorDatos=jsonEntrada.tipoMetadata;

    if(this.tipoVisorDatos==='JSON') this.objDocu = JSON.parse(this.documento.Data.metadata);
    if(this.tipoVisorDatos==='HTML') this.metadataHtml =this.sanitizer.bypassSecurityTrustHtml(this.documento.Data.metadata);
    if(this.tipoVisorDatos==='MD') this.metadataMD =  this.documento.Data.metadata

  }

  async onVolver() {
    this.emitExit.emit();
  }
}
