import { RouterModule, Routes } from '@angular/router';

import { NgModule } from '@angular/core';
import { LayoutComponent } from './components/layout/layout.component';
import { InicioComponent } from './components/inicio/inicio.component';
import { InfoComponent } from './components/info/info.component';
import { NotFoundComponent } from './components/not-found/not-foud.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'validar',
        component: InicioComponent,
      },
      {
        path: 'info',
        component: InfoComponent,
      },
      {
        path: '404',
        component: NotFoundComponent,pathMatch: 'full'
      },
      // {
      //   path: ':firmas',
      //   component: FirmsComponent,pathMatch: 'full'
      // },
      {
        path: '', redirectTo: '/validar', pathMatch: 'full'
      }
    ],
  },
  {
    path: '**',
    redirectTo: '/validar'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: true }),RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
