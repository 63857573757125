<footer>
    <div class="logos">
        <a routerLink="/inicio" title="Volver a inicio"><img class="logo-clinic"
                src="../../../assets/images/Firma-dispositivo/cas-logo-footer.png" alt="Logo clínica alemana"></a>
        <div class="divider"></div>
        <img class="logo-all-safe" src="../../../assets/images/Firma-dispositivo/img-todos-seguros.png"
            alt="imagen ">
    </div>
    <div class="footer-text">
        <p>Clínica Alemana, Todos los derechos Reservados</p>
        <p> <a class="custom-link" target="_blank" href="https://www.clinicaalemana.cl/terminos-y-condiciones/alemana">Términos y Condiciones</a> de uso de canales digitales de Clínica Alemana.</p>
    </div>
    <div class="contacts">
        <p>Mesa Central <span>+56 2 2210 1111</span></p>
        <p>Rescate Alemana <span>+56 2 2910 9911</span></p>
    </div>
</footer>