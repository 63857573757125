import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ValidarDocumentosService {

  constructor(private http: HttpClient) { }

    //Obtiene el documento pendiente de firma
    validarDocumento(hashDocumento:string): Observable<any>{
      return this.http.get<any>(environment.casMsValidarDocumentos + 'validador/' + hashDocumento)
          .pipe(catchError(this.handleError));
    }

    public handleError(error: HttpErrorResponse) {
      return throwError('Ha ocurrido un error en el sistema');
    }
}
