<div *ngIf="!levantaInfo" class="layout-inicio">
    <h1>Validación de Documentos Clínica Alemana</h1>
    <p>Validador de documentos emitidos por Clínica Alemana.</p>
    <form id="hash-code-input" autocomplete="off">
        <div class="inputSize">
            <cas-input-ext [isRequired]="true" (onInputText)="ingresarHashDocumento($event)"
                [value]="hashDocumento" initialLabel="Ingresa el código del documento" size="medium"
                [feedback]="feedback"  [isValid]="isValidInputExt"
                class="me-2"></cas-input-ext>
            <!-- <p class="text-error">Ingresa un código válido</p> -->
            <div class="pt-3">
                <re-captcha (resolved)="resolved($event)" errorMode="handled"
                    siteKey="6Ld_87goAAAAAF3ewVwPBazXced9GswfbU1qdMm7"></re-captcha>
            </div>
        </div>
        <!-- </div> -->
        <div class="col-7">

        </div>
        <button class="btn btn-primary" (click)="onContinue()" [disabled]="!isHashCompleto || !validacion || spinner">
            <span *ngIf="spinner" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"> </span>
            <span *ngIf="spinner">
                Continuar
            </span>
            <span *ngIf="!spinner">
                Continuar
            </span>
        </button>
    </form>
    <div>v:{{appVersion}}</div>
</div>
<!-- Componente INFO -->
<div *ngIf="levantaInfo">
    <app-info [documento]="respuestaServicio" (emitExit)="volver()" ></app-info>
</div>
