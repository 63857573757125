import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class EvidenciaFirmaService {
  documentoService: any;
  cryptoService: any;
  
  constructor(private http: HttpClient,
    private deviceService: DeviceDetectorService) { }

    // //Obtiene el documento pendiente de firma
    // getDocumentoAfirmar(pinId:string): Observable<any>{
    //   let pin_ = encodeURIComponent(pinId)
    //   return this.http.get<any>(environment.urlMsEvidenciaFirma +'/evidenciaFirma/getEvidenciaFirma?pinConfirmacionIn='+pin_)
    //       .pipe(catchError(this.handleError));
    // }


    // async envioCorreo(infoDoc: any, infoPac:any, infoCopia:any, hash:any,ppn:any) {
    //   let requestBody = {
    //     "infoPac":  infoPac,
    //     "infoCopia": infoCopia,
    //     "infoDoc": infoDoc,
    //     "infoDocHash":hash,
    //     "infoDocPpn":ppn
    //   }
    //   const headers = { 'content-type': 'application/json' }
    //   return this.http.post<any>(environment.urlMsEvidenciaFirma +'/evidenciaFirma/envioDocInfo', requestBody, { 'headers': headers }).pipe(catchError(this.handleError));
    // }

  
    // async subirDocFirmado(pin: any, firmaPaciente:any) {
    //   let pinEncrip_ = await this.encriptar8(pin);

    //   let requestBody = {
    //     "pinCode": pinEncrip_,
    //     "firmaPaciente": firmaPaciente
    //   }
    //   const headers = { 'content-type': 'application/json' }
    //   return this.http.post<any>(environment.urlMsEvidenciaFirma + `/evidenciaFirma/subirDocFirmado`, requestBody, { 'headers': headers }).pipe(catchError(this.handleError));
    // }

    // async subirEvidenciaDoc(docHash:any, idAccion:any, fechaEvidencia:any, accionPlataforma:any, obsEvidencia:any) {
    //   let requestBody = {
    //     "docHash":docHash,
    //     "idAccion":idAccion,
    //     "fechaEvidencia":fechaEvidencia,
    //     "accionPlataforma":accionPlataforma,
    //     "obsEvidencia":obsEvidencia
    //   }

    //   const headers = { 'content-type': 'application/json' }
    //   return this.http.post<any>(environment.urlMsEvidenciaFirma + `/evidenciaFirma/InsEvidenciasDoc`, requestBody, { 'headers': headers }).pipe(catchError(this.handleError));
    // }

    
    // async updateEvidenciaFirma(evidenciaFirma: any) {
    //   let requestBody = evidenciaFirma;
  
    //   const headers = { 'content-type': 'application/json' }
    //   return this.http.post<any>(environment.urlMsEvidenciaFirma +`/evidenciaFirma/updEvidenciaFirma`, requestBody, { 'headers': headers }).pipe(catchError(this.handleError));
    // }

    // async convertirDoc(evidenciaFirma: string) {
    //   let requestBody = {
    //     "infoDoc":evidenciaFirma
    //   };
  
    //   const headers = { 'content-type': 'application/json'}
    //   return this.http.post<any>(environment.urlMsEvidenciaFirma +`/evidenciaFirma/convertirDoc`, requestBody, { 'headers': headers }).pipe(catchError(this.handleError));
    // }

    //Manejador de errores
      public handleError(error: HttpErrorResponse) {
        return throwError('Ha ocurrido un error en el sistema');
    }

    
    //Obtiene el documento pendiente de firma
    obtenerIp(): Observable<any>{
      return this.http.get<any>('https://api.ipify.org?format=json')
          .pipe(catchError(this.handleError));
    }

  async obtenerPlataforma() { 
    let navegador: string = this.deviceService.browser; 
    let navegadorVersion: string = this.deviceService.browser_version; 
    let sistemaOperativo: string = this.deviceService.os; 
    let sistemaOperativoVersion: string = this.deviceService.os_version; 
    let dispositivo: string = this.deviceService.device; 
    let dispositivoVersion: string = this.deviceService.deviceType; 
    let informacion: string = ''; 
    if (navegador != '' && navegador != 'Unknown') {
      informacion = navegador;
      if (navegadorVersion != '' && navegadorVersion != 'Unknown') {
         informacion = informacion + ' ' + navegadorVersion; 
        } 
      } 
      if (sistemaOperativo != '' && sistemaOperativo != 'Unknown') {
         if (informacion != '') {
           informacion = informacion + ', ' + sistemaOperativo; 
          } 
          else {
             informacion = informacion + ' ' + sistemaOperativo; 
          } 
          if (sistemaOperativoVersion != '' && sistemaOperativoVersion != 'Unknown') {
           informacion = informacion + ' ' + sistemaOperativoVersion; 
          } 
        }
        else if (sistemaOperativoVersion != '' && sistemaOperativoVersion != 'Unknown') {
         if (informacion != '') {
          informacion = informacion + ', ' + sistemaOperativoVersion; 
        } 
        else {
          informacion = informacion + ' ' + sistemaOperativoVersion;
        }
      } 
      if (dispositivo != '' && dispositivo != 'Unknown') {
        if (informacion != '') {
          informacion = informacion + ', ' + dispositivo;
        }
        else {
          informacion = informacion + ' ' + dispositivo;
        } 
        if (dispositivoVersion != '' && dispositivoVersion != 'Unknown') {
          informacion = informacion + ' ' + dispositivoVersion;
        }
      } 
      else if (dispositivoVersion != '' && dispositivoVersion != 'Unknown') {
        if (informacion != '') {
          informacion = informacion + ', ' + dispositivoVersion;
        }
        else {
          informacion = informacion + ' ' + dispositivoVersion;
        }
      }
      let ip = this.documentoService.getIp();
      if(ip != ''){
        if(informacion.trim() == ''){
          informacion = informacion + 'IP ' + ip;
        }else{
          informacion = informacion + ', IP ' + ip;
        }
      }
      return informacion;
    }

    TTLPinCode(fechaEnvio:any){
      var now = moment(new Date());
      var end = moment(fechaEnvio).toDate(); 
      var duration = moment.duration(now.diff(end));
      var minDiff = duration.asMinutes();
      return minDiff;
    }

    async encriptar(key:any){
      let llave = this.cryptoService.encriptar(key)
      let llave_ = encodeURIComponent(llave);
      return llave_
    }

    // async encriptarAes(key:any){
    //   const cryptoPasword = environment.cryptoPassword;
    //   let llave = CryptoJS.AES.encrypt(key, cryptoPasword).toString();
    //   return llave;
    // }

    // async encriptar8(key:any){
    //   const cryptoPasword = environment.cryptoPassword;
    //   let encrypted = CryptoJS.AES.encrypt(key, cryptoPasword).toString();
    //   return encrypted;
    // }
      //Obtiene el documento pendiente de firma
    //   descargarDocumento(identificador:string): Observable<any>{
    //     return this.http.get<any>(environment.urlMsEvidenciaFirma+'/evidenciaFirma/getDocuEvidencia?docHash='+identificador)
    //         .pipe(catchError(this.handleError));
    // }
}
