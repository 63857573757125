import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ValidarDocumentosService } from 'src/app/services/validar-documentos.service';
import {version} from '../../../../package.json';
@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit, AfterViewInit {

  //VARIABLES
  validacion: boolean = false;
  spinner: boolean = false;
  hashDocumento: string = '';
  isHashCompleto: boolean = false;
  objDocu: any = {};
  feedback: string='';
  isValidInputExt: boolean=true;
  respuestaServicio:any ={};
  @ViewChild('modalContingencia') modalContingencia_!: ElementRef
  modalContingencia: any;
  levantaInfo:boolean=false;
  tipoVisorDatos:string="JSON";
  modaloComponente: any=false;
  appVersion:string="";

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private validarDocumentosService:ValidarDocumentosService) { }


  ngOnInit(): void {
    this.appVersion=version;
    if(this.route.queryParams){
    this.route.queryParams.subscribe(params=>{
      if(params.UUID)
      this.ingresarHashDocumento(params.UUID);

 
    })
  }

  }

  ngAfterViewInit(): void {
   // this.modalContingencia = new (window as any).bootstrap.Modal(this.modalContingencia_.nativeElement);
  }

  ingresarHashDocumento(texto: any) {
    this.hashDocumento = texto;
    if(this.hashDocumento.length > 10)
      this.isHashCompleto = true;
    else
    this.isHashCompleto = false;
  }

  async onContinue() {
    this.spinner = true;
    try {
      const respuesta = await this.validarDocumentosService.validarDocumento(this.hashDocumento).toPromise().finally(() => this.spinner = false);
      this.respuestaServicio = respuesta;
      if (respuesta.Data) {
        this.tipoVisorDatos=(respuesta.Data.tipoMetadata)?respuesta.Data.tipoMetadata:'JSON';
        console.log(' this.tipoVisorDatos', this.tipoVisorDatos)
        this.objDocu = respuesta.Data.metadata;
      
          this.levantaInfo=true; // componente
        
      } else if (respuesta.message) {

        this.isValidInputExt = false;
        this.feedback = "No se ha podido encontrar documento.";
      }
    }
    catch (err) {
      this.isValidInputExt = false;
      this.feedback = "No se ha podido encontrar documento.";
    }
  }

  resolved(captchaResponse: string) {
   if(captchaResponse != null){
    this.validacion = true
   } else {
    this.validacion = false
   }
  }

  volver(){
    this.levantaInfo=false;
  }

}
