import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EvidenciaFirmaService } from 'src/app/services/evidencia-firma.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  documentoService: any;
  ipActual:any;
  constructor(public router: Router, private evidenciaFirmaService:EvidenciaFirmaService) { }

  ngOnInit(): void {
    this.evidenciaFirmaService.obtenerIp().toPromise().then(resp =>{ this.ipActual = resp.ip});
  }

  

}
